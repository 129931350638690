.time-container {
  display: flex;
  justify-content: space-between; }

.remove-toggle-arrow::before {
  content: none !important; }

.remove-toggle-arrow::after {
  content: none !important; }

.remove-toggle-arrow:focus {
  box-shadow: none !important; }

.remove-box-shadow:focus {
  box-shadow: none !important;
  border-bottom: 2px solid #ccc !important;
  border-radius: none !important; }

.round-button {
  width: 56px;
  height: 56px;
  border: none !important;
  border-radius: 50% !important;
  background-color: #269ae0 !important; }

.round-button-sm-primary {
  width: 40px;
  height: 40px;
  border: none !important;
  border-radius: 50% !important;
  background-color: #269ae0 !important; }

.round-button-search {
  width: 45px;
  height: 45px;
  border: none !important;
  border-radius: 50% !important;
  background-color: #269ae0 !important; }

.round-button-sm {
  width: 40px;
  height: 40px;
  border: 1px solid #269ae0 !important;
  border-radius: 50% !important;
  background-color: transparent !important; }

.round-button-sm-danger {
  width: 40px;
  height: 40px;
  border: 1px solid #d0021b !important;
  border-radius: 50% !important;
  background-color: transparent !important; }

.btn-light-sm {
  border-radius: 50% !important;
  width: 40px;
  height: 40px; }

.btn-light-sm:hover {
  border-radius: 50% !important;
  width: 40px;
  height: 40px;
  background-color: white !important;
  border-color: #d4d5d6 !important; }

.round-button-sm:hover {
  background-color: #269ae0 !important; }

.round-button-sm-danger:hover {
  background-color: #d0021b !important; }

.round-button-sm:hover .primary-color,
.round-button-sm-danger:hover .red-color {
  color: #fff !important; }

.primary-color {
  color: #269ae0 !important; }

.red-color {
  color: #d0021b; }

.btn-warning {
  background-color: #f8d6a7 !important;
  border-color: #f8d6a7 !important; }

.btn-danger {
  background-color: #d0021b !important;
  border-color: #d0021b !important; }

.btn-primary {
  background-color: #269ae0 !important;
  border-color: #269ae0 !important; }

.btn-primary:focus {
  background-color: #269ae0 !important;
  border-color: #269ae0 !important; }

.bg-color-primary {
  background-color: #269ae0 !important;
  border-color: #269ae0 !important; }

.form-check-input:checked {
  background-color: #269ae0 !important;
  border-color: #269ae0 !important; }

.add-background-on-hover:hover,
.add-background-on-hover:focus {
  background-color: #ddd; }

.style-nav-links {
  margin-left: "10px";
  color: #fff !important;
  letter-spacing: 1px; }

@media (min-width: 600px) {
  .sm-above {
    display: inline !important;
    width: calc(100% - 55px) !important; } }

.btn-outline-warning {
  border-color: #f8d6a7 !important; }

.btn-outline-warning:active,
.btn-outline-warning:focus,
.btn-outline-warning:hover,
.btn-check:checked + .btn-outline-warning,
.btn-outline-warning.active {
  background-color: #f8d6a7 !important;
  border-color: #f8d6a7 !important; }

.btn-outline-danger {
  border-color: #d0021b !important; }

.btn-outline-danger:active,
.btn-outline-danger:focus,
.btn-outline-danger:hover,
.btn-check:checked + .btn-outline-danger,
.btn-outline-danger.active {
  background-color: #d0021b !important;
  border-color: #d0021b !important; }

.btn-outline-success {
  border-color: #59b78c !important; }

.btn-outline-success:active,
.btn-outline-success:focus,
.btn-outline-success:hover,
.btn-check:checked + .btn-outline-success,
.btn-outline-success.active {
  background-color: #59b78c !important;
  border-color: #59b78c !important; }

.btn-forgot-password {
  background-color: #e6e8ea !important; }

.btn-forgot-password:hover {
  background-color: #cfd0d1 !important; }

.action-card {
  display: flex;
  align-content: center;
  padding: 15px;
  box-shadow: #e6e8ea;
  background-color: #fff;
  margin-bottom: 10px; }

.custom-chip {
  display: inline-block;
  padding: 0.35em 0.65em;
  font-size: 1em;
  font-weight: 700;
  line-height: 1;
  color: white;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem; }

.custom-chip-lg {
  display: inline-block;
  padding: 0.35em 0.65em;
  font-size: 1.2em;
  font-weight: 600;
  line-height: 2;
  color: white;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border-radius: 0.25rem;
  width: 70px; }

.nav-background-color {
  background-color: #0c2e3d !important; }

.background-primary {
  background-color: #269ae0; }

.background-secondary {
  background-color: grey; }

.background-success {
  background-color: #59b78c; }

.background-warning {
  background-color: #f8d6a7; }

.background-danger {
  background-color: #d0021b; }

.dashboard-boxes {
  height: 150px;
  width: 100%;
  position: relative;
  border: 1px solid #ddd;
  padding: 10px; }

.center-div {
  color: white;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100px;
  height: 30px;
  margin: auto; }

.dashboard-box-footer {
  padding: 10px; }

.custom-spinner {
  height: 150px !important;
  width: 150px !important;
  color: #269ae0 !important; }

.react-datepicker__input-container input {
  width: 100%;
  padding: 5px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
